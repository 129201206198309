import type {
  FilterSelectedOptionsCarDto,
  SearchDataDto,
  SelectedOptionsDealersDto,
} from '~/types/client'
import type { SelectedOptionsNewsDto, NumberRangeDto } from '~/types/common'

export abstract class StringDecodeStrategyAbstract<T extends FilterSelectedOptionsCarDto | SelectedOptionsDealersDto | SelectedOptionsNewsDto> {
  abstract readonly pattern: string

  protected readonly slugPattern: string = '(\\d+|(\\w|-|,)+)'
  protected readonly uuidPattern = '([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'
  protected readonly numberArrayPattern = '(\\d+(,\\d+)*$)'
  protected readonly numberRangePattern = '(\\d*\\.?\\d*(,\\d*\\.?\\d*)?)'
  protected readonly conditionPattern = '(true|false)'
  protected readonly orderPattern = '(asc|desc|-?\\d+(\\.\\d+)?,-?\\d+(\\.\\d+)?)'

  protected readonly slugAndNumberRangePattern = `${this.slugPattern}|${this.numberRangePattern}`

  support(searchItemString: string): boolean {
    return RegExp(this.pattern).test(searchItemString)
  }

  abstract process(subject: SearchDataDto<T>, searchItemString: string): void

  protected parseRange(input: string): NumberRangeDto {
    const data = input.split(',')
    const minData = parseInt(data[0])
    const maxData = parseInt(data[1])

    return {
      min: Number.isInteger(minData) ? minData : null,
      max: Number.isInteger(maxData) ? maxData : null,
    }
  }

  protected parseFloatRange(input: string): NumberRangeDto {
    const data = input.split(',')

    return {
      min: parseFloat(data[0]),
      max: parseFloat(data[1]) || null,
    }
  }
}
