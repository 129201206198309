








































import { Mixins, Component, Prop, Emit, Ref } from 'vue-property-decorator'
import type {
  FileDto,
  MultiSizeImageDto,
  TempFileDto,
  UnknownObject,
} from '~/types/common'
import {
  ImageFormatEnum,
  ImageSizeEnum,
} from '~/types/common'
import ObserveVisibilityAbstract from '~/components/client/abstract-pages/scroll/observe-visibility.abstract'
import type { VImgInterface } from '~/components/common/v-img/v-img.interface'
import { LoadingWrapper } from '~/decorators'

@Component
export default class VImg extends Mixins(ObserveVisibilityAbstract) implements VImgInterface {
  readonly imageCacheKey = process.env.imageCacheKey

  @Prop({ required: true })
  image!: FileDto | TempFileDto | MultiSizeImageDto | null

  @Prop({ default: ImageSizeEnum.FULL })
  readonly size!: ImageSizeEnum

  @Prop({ default: ImageFormatEnum.WEBP })
  readonly format!: ImageFormatEnum

  @Prop({ default: null, type: String })
  readonly alt!: string | null

  @Prop({ default: 'mw-100' })
  readonly imgClass!: string

  @Prop({ default: false, type: Boolean })
  readonly withGalleryView!: boolean

  @Prop({ default: () => ({}), type: Object })
  readonly galleryViewOptions!: UnknownObject

  @Prop({ type: Boolean, default: false })
  readonly crossorigin!: boolean

  @Ref('image')
  readonly imageRef?: HTMLImageElement

  @Prop({ default: false, type: Boolean })
  readonly withLoader!: boolean

  @Prop({ default: false })
  readonly withInitials!: boolean

  @Prop({ default: 'image' })
  readonly initials!: string

  @Prop({ default: '' })
  readonly styles?: string

  loading = this.withLoader

  get path(): string | null {
    if (!this.image) {
      return null
    }

    if ('path' in this.image) {
      return this.image.path
    }

    if ('pathList' in this.image) {
      return `${this.image.pathList[this.size]}.${this.format}`
    }

    return null
  }

  handleError() {
    this.image = null
  }

  get webpPath(): string | null {
    if (!this.image) {
      return null
    }

    if ('path' in this.image) {
      return this.image.path
    }

    if ('pathList' in this.image) {
      return `${this.image.pathList[this.size]}.${ImageFormatEnum.WEBP}`
    }

    return null
  }

  get computedAlt(): string | null {
    return this.alt ?? this.$t('image-alt')
  }

  getAbsolutePath(path: string | null): string {
    if (!path) {
      return require(`~/assets/images/empty-elements/empty-photo-${this.size}.jpg`)
    }

    if (this.image && 'path' in this.image) {
      return `${process.env.backendUrl}${this.$serverUrlFormatter(path)}?${this.imageCacheKey}`
    }

    return `${this.$serverUrlFormatter(path)}?${this.imageCacheKey}`
  }

  openGallery(): void {
    if (!this.withGalleryView || !this.imageRef) {
      return
    }

    this.imageRef.click()
  }

  mounted() {
    if (!this.imageRef) {
      return
    }

    if (!this.crossorigin) {
      return
    }

    this.imageRef.crossOrigin = 'anonymous'
  }

  @Emit()
  @LoadingWrapper()
  onLoadImg(event: Event) {
    return event
  }
}
